import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import GetDagcoins from "../components/get-dagcoins";
import Headertransparent from "../components/headertransparent";
import Hero from "../components/hero";
import Layout from "../components/layout";
import { Seo } from "../components/seo";
function Ecosystem({ data }) {
  var dagTechnology = data.prismicEcosystem.data.dagcoin_technology;
  var dagFinancial = data.prismicEcosystem.data.financial_services;
  var dagEntertaiment = data.prismicEcosystem.data.entertainment1;
  var dagSocial = data.prismicEcosystem.data.social_services1;
  var dagShopping = data.prismicEcosystem.data.shopping___usage;
  var dagMerchant = data.prismicEcosystem.data.merchant_services1;
  if (data.prismicEcosystem.lang === "en-us") {
    var currentUrl = "/ecosystem/";
    var learnMore = "Learn more";
    var readMore = "Read more";
  } else if (data.prismicEcosystem.lang === "et-ee") {
    currentUrl = "/et/ecosystem/";
    learnMore = "Loe edasi";
    readMore = "Loe edasi";
  } else {
    currentUrl = "/ecosystem/";
    learnMore = "Learn more";
    readMore = "Read more";
  }

  var NextUid = data.prismicEcosystem.alternate_languages[0].uid;

  if (data.prismicEcosystem.lang === "en-us") {
    var NextUrl = "/et/" + NextUid;
  } else if (data.prismicEcosystem.lang === "et-ee") {
    NextUrl = "/" + NextUid;
  } else {
    NextUrl = "wrong lang";
  }

  return (
    <Layout htmllang={data.prismicEcosystem.lang}>
      <Headertransparent
        htmllang={data.prismicEcosystem.lang}
        langswitcher={NextUrl}
      />
      <Hero padding={200}>
        <div className="herobg">
          <GatsbyImage
            alt="Dagcoin"
            image={data.ecobgimg.childImageSharp.gatsbyImageData}
          />
        </div>
        <PrismicRichText
          field={data.prismicEcosystem.data.ecosystem_title_header.richText}
        />
        <p>{data.prismicEcosystem.data.ecosystem_description.text}</p>
        <a href="#more" className="btn btn--orange">
          {learnMore}
        </a>
      </Hero>
      <Container className="middle-eco firsteco" id="more">
        <div className="eco-bgfull">
          <GatsbyImage
            alt="Dagcoin"
            image={data.ecof.childImageSharp.gatsbyImageData}
          />
        </div>
        <GatsbyImage
          alt="Dagcoin"
          image={data.dagcointechnology.childImageSharp.gatsbyImageData}
        />
        <h3>{data.prismicEcosystem.data.dagcoin_technology1.text}</h3>
        <p>{data.prismicEcosystem.data.dagcoin_technology_description.text}</p>
      </Container>
      <Container className="ecosystemcontainer">
        <Row>
          {dagTechnology.map((value, index) => {
            return (
              <Col key={index}>
                <div className="ecosystemwrappercol">
                  <Link to={currentUrl + value.ecosystem_link.slug}>
                    <img
                      alt={value.dagcoin_technology_title.text}
                      src={value.ecosystem_icon.url}
                    />
                    <h3>{value.dagcoin_technology_title.text}</h3>
                    <p>{value.ecosystem_shortdescription.text}</p>
                    <p>
                      <span>{readMore}</span>
                    </p>
                  </Link>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>

      <Container className="middle-eco secondeco">
        <div className="eco-bgfull">
          <GatsbyImage
            alt="Dagcoin"
            image={data.financialservices.childImageSharp.gatsbyImageData}
          />
        </div>
        <GatsbyImage
          alt="Dagcoin"
          image={data.financialserviceslogo.childImageSharp.gatsbyImageData}
        />
        <h3>{data.prismicEcosystem.data.financial_services1.text}</h3>
        <p>{data.prismicEcosystem.data.financial_services_description.text}</p>
      </Container>
      <Container className="ecosystemcontainer">
        <Row>
          {dagFinancial.map((value, index) => {
            return (
              <Col key={index}>
                <div className="ecosystemwrappercol">
                  <Link to={currentUrl + value.ecosystem_link.slug}>
                    <img
                      alt={value.financial_services_title.text}
                      src={value.ecosystem_icon.url}
                    />
                    <h3>{value.financial_services_title.text}</h3>
                    <p>{value.ecosystem_shortdescription.text}</p>
                    <p>
                      <span>{readMore}</span>
                    </p>
                  </Link>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>

      <Container className="middle-eco secondeco">
        <div className="eco-bgfull">
          <GatsbyImage
            alt="Dagcoin"
            image={data.entertaimentlogo.childImageSharp.gatsbyImageData}
          />
        </div>
        <GatsbyImage
          alt="Dagcoin"
          image={data.entertaiment.childImageSharp.gatsbyImageData}
        />
        <h3>{data.prismicEcosystem.data.entertainment.text}</h3>
        <p>{data.prismicEcosystem.data.entertainment_description.text}</p>
      </Container>
      <Container className="ecosystemcontainer">
        <Row>
          {dagEntertaiment.map((value, index) => {
            return (
              <Col key={index}>
                <div className="ecosystemwrappercol">
                  <Link to={currentUrl + value.ecosystem_link.slug}>
                    <img
                      alt={value.entertainment_title.text}
                      src={value.ecosystem_icon.url}
                    />
                    <h3>{value.entertainment_title.text}</h3>
                    <p>{value.entertainment_description1.text}</p>
                    <p>
                      <span>{readMore}</span>
                    </p>
                  </Link>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>

      <Container className="middle-eco secondeco">
        <div className="eco-bgfull">
          <GatsbyImage
            alt="Dagcoin"
            image={data.socialbg.childImageSharp.gatsbyImageData}
          />
        </div>
        <GatsbyImage
          alt="Dagcoin"
          image={data.socialservices.childImageSharp.gatsbyImageData}
        />
        <h3>{data.prismicEcosystem.data.social_services.text}</h3>
        <p>{data.prismicEcosystem.data.social_services_description.text}</p>
      </Container>
      <Container className="ecosystemcontainer">
        <Row>
          {dagSocial.map((value, index) => {
            return (
              <Col key={index}>
                <div className="ecosystemwrappercol">
                  <Link to={currentUrl + value.ecosystem_link.slug}>
                    <img
                      alt={value.social_services_title.text}
                      src={value.social_services2.url}
                    />
                    <h3>{value.social_services_title.text}</h3>
                    <p>{value.social_services_description1.text}</p>
                    <p>
                      <span>{readMore}</span>
                    </p>
                  </Link>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>

      <Container className="middle-eco secondeco">
        <div className="eco-bgfull">
          <GatsbyImage
            alt="Dagcoin"
            image={data.shoppingbg.childImageSharp.gatsbyImageData}
          />
        </div>
        <GatsbyImage
          alt="Dagcoin"
          image={data.shoppingicon.childImageSharp.gatsbyImageData}
        />
        <h3>{data.prismicEcosystem.data.shoppingusage.text}</h3>
        <p>{data.prismicEcosystem.data.shoppingusage_description.text}</p>
      </Container>
      <Container className="ecosystemcontainer">
        <Row>
          {dagShopping.map((value, index) => {
            return (
              <Col key={index}>
                <div className="ecosystemwrappercol">
                  <Link to={currentUrl + value.ecosystem_link.slug}>
                    <img
                      alt={value.shopping___usage_title.text}
                      src={value.shopping___usage_image.url}
                    />
                    <h3>{value.shopping___usage_title.text}</h3>
                    <p>{value.shopping___usage_description.text}</p>
                    <p>
                      <span>{readMore}</span>
                    </p>
                  </Link>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>

      <Container className="middle-eco secondeco">
        <div className="eco-bgfull">
          <GatsbyImage
            alt="Dagcoin"
            image={data.merchantservicesbg.childImageSharp.gatsbyImageData}
          />
        </div>
        <GatsbyImage
          alt="Dagcoin"
          image={data.merchantserviceslogo.childImageSharp.gatsbyImageData}
        />
        <h3>{data.prismicEcosystem.data.merchant_services.text}</h3>
        <p>{data.prismicEcosystem.data.merchant_services_description.text}</p>
      </Container>
      <Container className="ecosystemcontainer">
        <Row>
          {dagMerchant.map((value, index) => {
            return (
              <Col key={index}>
                <div className="ecosystemwrappercol">
                  <Link to={currentUrl + value.ecosystem_link.slug}>
                    <img
                      alt={value.merchant_services_title.text}
                      src={value.merchant_services_image.url}
                    />
                    <h3>{value.merchant_services_title.text}</h3>
                    <p>{value.merchant_services_description1.text}</p>
                    <p>
                      <span>{readMore}</span>
                    </p>
                  </Link>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </Layout>
  );
}

export const Head = ({ data }) => {
  return (
    <>
      <Seo
        // TODO: Description should come from "seo_description" query
        description={data.prismicEcosystem.data.ecosystem_description.text}
        pathname={data.prismicEcosystem.uid}
        // TODO: Title should come from "seo_title" query
        title={"Ecosystem timeline"}
        // lang={data.prismicEcosystem.lang}
      />
    </>
  );
};

export const pageQuery = graphql`
  query ($uid: String, $lang: String) {
    prismicEcosystem(uid: { eq: $uid }, lang: { eq: $lang }) {
      lang
      uid
      alternate_languages {
        id
        lang
        type
        uid
      }

      data {
        financial_services {
          ecosystem_icon {
            url
          }
          ecosystem_shortdescription {
            text
          }
          financial_services_title {
            text
          }
          ecosystem_link {
            slug
          }
        }
        dagcoin_technology {
          dagcoin_technology_title {
            text
          }
          ecosystem_icon {
            url
          }
          ecosystem_link {
            slug
          }
          ecosystem_shortdescription {
            text
          }
        }
        entertainment1 {
          ecosystem_icon {
            url
          }
          ecosystem_link {
            slug
          }
          entertainment_description1 {
            text
          }
          entertainment_title {
            text
          }
        }
        social_services1 {
          ecosystem_link {
            slug
          }
          social_services2 {
            url
          }
          social_services_description1 {
            text
          }
          social_services_title {
            text
          }
        }
        shopping___usage {
          ecosystem_link {
            slug
          }
          shopping___usage_description {
            text
          }
          shopping___usage_image {
            url
          }
          shopping___usage_title {
            text
          }
        }
        merchant_services1 {
          merchant_services_description1 {
            text
          }
          merchant_services_image {
            url
          }
          merchant_services_title {
            text
          }
          ecosystem_link {
            slug
          }
        }
        ecosystem_title_header {
          text
          richText
        }
        social_services_description {
          text
        }
        social_services {
          text
        }
        shoppingusage_description {
          text
        }
        shoppingusage {
          text
        }
        merchant_services_description {
          text
        }
        merchant_services {
          text
        }
        financial_services_description {
          text
        }
        financial_services1 {
          text
        }
        entertainment_description {
          text
        }
        entertainment {
          text
        }
        ecosystem_description {
          text
        }
        dagcoin_technology_description {
          text
        }
        dagcoin_technology1 {
          text
        }
      }
    }
    shoppingbg: file(relativePath: { eq: "shoppingbg.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
    shoppingicon: file(relativePath: { eq: "shoppingicon.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, quality: 99, layout: CONSTRAINED)
      }
    }
    socialbg: file(relativePath: { eq: "socialbg.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
    socialservices: file(relativePath: { eq: "socialservices.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, quality: 99, layout: CONSTRAINED)
      }
    }
    entertaiment: file(relativePath: { eq: "entertaiment.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
    entertaimentlogo: file(relativePath: { eq: "entertaimentlogo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, quality: 99, layout: CONSTRAINED)
      }
    }
    merchantservicesbg: file(relativePath: { eq: "merchantservicesbg.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
    merchantserviceslogo: file(
      relativePath: { eq: "merchantserviceslogo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300, quality: 99, layout: CONSTRAINED)
      }
    }
    financialservices: file(relativePath: { eq: "financialservices.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
    financialserviceslogo: file(
      relativePath: { eq: "financialserviceslogo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300, quality: 99, layout: CONSTRAINED)
      }
    }
    dagcointechnology: file(relativePath: { eq: "dagcointechnology.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, quality: 99, layout: CONSTRAINED)
      }
    }
    ecof: file(relativePath: { eq: "ecof.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
    exampleicon: file(relativePath: { eq: "webwallet.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, quality: 99, layout: CONSTRAINED)
      }
    }
    bgsecond: file(relativePath: { eq: "bg2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
    ecobgimg: file(relativePath: { eq: "ecosystembackground.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
    ecobg: file(relativePath: { eq: "ecobg.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
    girl: file(relativePath: { eq: "girl.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
    homebg: file(relativePath: { eq: "homebg.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
    road: file(relativePath: { eq: "road.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
    mapttwo: file(relativePath: { eq: "map-2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
    mapbg: file(relativePath: { eq: "map-bg.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
    whitebg: file(relativePath: { eq: "bg.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
    location: file(relativePath: { eq: "location.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 99, layout: CONSTRAINED)
      }
    }
  }
`;
export default Ecosystem;
